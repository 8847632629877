import { FunctionComponent } from 'react'
import styled from 'styled-components'

const DividerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  width: 100%;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    margin: 0 50px;
    width: auto;
  }
`

interface IDividerProps {
  marginTop?: number
}
const Divider = styled.div<IDividerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.colors.grey30};
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    width: 1px;
    height: 276px;
    ${props => `margin-top: ${props.marginTop || 4}px;`}
  }
`

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  position: absolute;
  padding-top: 2px;
  width: 44px;
  height: 44px;
  border: 1px solid ${props => props.theme.colors.grey30};
  border-radius: 50%;
  background-color: ${props => props.theme.colors.white};
`

interface IProps {
  marginTop?: number
}
const OrDivider: FunctionComponent<IProps> = ({ marginTop }) => (
  <DividerWrapper>
    <Divider marginTop={marginTop}>
      <Circle>OR</Circle>
    </Divider>
  </DividerWrapper>
)

export default OrDivider
