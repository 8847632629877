import { ChangeEvent, FunctionComponent, useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'

import EyeIcon from 'components/SVG/Eye'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

interface IInputProps {
  background?: string
  borderColor?: string
  borderRadius?: string
  error?: boolean
  inputHeight?: number
  textColor?: string
  placeholderTextColor?: string
  uppercase?: boolean
  visible: boolean
}
const StyledInput = styled.input<IInputProps>`
  padding-left: 10px;
  padding-top: ${props => (props.visible ? 1 : 2)}px; /* font correction */
  background: ${props => props.background || props.theme.colors.grey2};
  ${props => props.error && `background: ${props.theme.colors.transparentRedAlpha10};`}
  border: ${props => `${props.error ? 2 : 1}px solid ${
    props.error ? props.theme.colors.red : props.borderColor || props.theme.colors.grey30
  }
  `};
  box-sizing: border-box;
  border-radius: ${props => props.borderRadius || '4px'};

  width: 100%;
  height: 40px;
  ${props => props.inputHeight && `height: ${props.inputHeight}px;`}

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  caret-color: ${props => props.theme.colors.blue};
  color: ${props => props.textColor || props.theme.colors.black};

  /* uppercase prop denotes a set of uppercase styles */
  /* it doesn't actually make the password uppercase (bad usability), only the placeholder */
  ${props =>
    props.uppercase
    && `
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1.28571px;
  `}

  &::placeholder {
    color: ${props => props.placeholderTextColor || props.theme.colors.grey27};
    ${props => props.disabled && `color: ${props.theme.colors.grey3};`}
    ${props => props.uppercase && 'text-transform: uppercase;'}
    opacity: 1;
  }
  &::-webkit-input-placeholder {
    color: ${props => props.placeholderTextColor || props.theme.colors.grey27};
    ${props => props.disabled && `color: ${props.theme.colors.grey3};`}
    ${props => props.uppercase && 'text-transform: uppercase;'}
    opacity: 1;
  }

  &:focus {
    outline: none;
    border: 1px solid ${props => props.theme.colors.blue5};
  }
`

const VisibilityToggleOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`

export interface IPasswordInputProps {
  background?: string
  borderColor?: string
  borderRadius?: string
  disabled?: boolean
  error?: boolean
  submissionError?: boolean
  inputHeight?: number
  name: string
  placeholder?: string
  placeholderTextColor?: string
  textColor?: string
  uppercase?: boolean
  value: string
  onChange(field: string, value: string): void
}

const PasswordInput: FunctionComponent<IPasswordInputProps> = ({
  background,
  borderColor,
  borderRadius,
  disabled,
  error,
  submissionError,
  inputHeight,
  name,
  placeholder,
  placeholderTextColor,
  textColor,
  uppercase,
  value,
  onChange,
}) => {
  const theme = useContext(ThemeContext)

  const [visible, setVisible] = useState(false)
  const toggleVisibility = () => setVisible(!visible)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(name, event?.target?.value)
  }

  return (
    <Container>
      <StyledInput
        data-testid={name}
        background={background}
        borderColor={borderColor}
        borderRadius={borderRadius}
        disabled={disabled}
        error={error || submissionError}
        id={name}
        inputHeight={inputHeight}
        placeholder={placeholder || 'Password'}
        placeholderTextColor={placeholderTextColor}
        textColor={textColor}
        type={visible ? 'text' : 'password'}
        uppercase={uppercase}
        value={value}
        visible={visible}
        onChange={handleChange}
      />
      <VisibilityToggleOverlay onClick={toggleVisibility}>
        <EyeIcon
          fill={theme.colors[visible ? 'blue' : 'grey11']}
          width={21}
          height={11}
        />
      </VisibilityToggleOverlay>
    </Container>
  )
}

export default PasswordInput
