import SVG from './SVG'
import * as T from './types'

const Eye = (props: T.ISVGProps) => (
  <SVG
    {...props}
    viewBox="0 0 21 11"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M19.4752 4.15894C17.5274 2.49714 14.248 0.0821282 10.1223 0H10.1096H10.0969C5.97271 0.0821282 2.68971 2.49714 0.741923 4.15894L0.73013 4.16889C0.604688 4.27434 0.0104265 4.7739 0.000212814 5.4064C-0.00755009 5.8681 0.197814 6.20919 0.49351 6.49479C2.08491 8.1085 5.55775 10.9778 10.0976 10.9985V11H10.1103H10.123V10.9985C14.6714 10.9778 18.1336 8.1085 19.7257 6.49479C20.0214 6.20919 20.2261 5.8681 20.2176 5.4064C20.206 4.77054 19.6067 4.26903 19.4851 4.16724L19.4752 4.15894ZM10.1096 9.75698C7.87815 9.74144 6.07363 7.84065 6.07363 5.49815C6.07363 3.15565 7.87815 1.25486 10.1096 1.24006C12.3468 1.25486 14.1449 3.15565 14.1449 5.49815C14.1449 7.84065 12.3468 9.7407 10.1096 9.75698ZM10.1096 3.84227C10.1139 3.84227 10.1181 3.84227 10.1223 3.84301C10.9826 3.86521 11.6629 4.5977 11.6629 5.49815C11.6629 6.3986 10.9826 7.13184 10.1223 7.15329V7.15477C10.1197 7.15477 10.1172 7.15434 10.1149 7.15394C10.113 7.1536 10.1112 7.15329 10.1096 7.15329C10.1075 7.15329 10.1054 7.15366 10.1033 7.15403C10.1012 7.1544 10.099 7.15477 10.0969 7.15477V7.15329C9.2423 7.13184 8.55634 6.3986 8.55634 5.49815C8.55634 4.5977 9.24301 3.86521 10.0969 3.84301V3.84227H10.1096Z"
  />
)

export default Eye
