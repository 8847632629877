import { useRouter } from 'next/router'
import React, { FunctionComponent, useContext, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'
import colors from 'themes/colors'

import RoundedButton from 'components/RoundedButton'
import { Apple, Facebook, Google } from 'components/SVG/social'
import Spacer from 'components/Spacer'

const SocialSignOnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    width: 300px;
    height: 320px;
  }
`

const renderFacebookIcon = () => (
  <Facebook
    width={18}
    height={28}
    fill={colors.facebookBlue}
  />
)

const renderGoogleIcon = () => (
  <Google
    width={27}
    height={28}
  />
)

const renderAppleIcon = () => (
  <Apple
    width={24}
    height={28}
  />
)

interface IProps {
  isSignup?: boolean
  handleSsoError(errMessage: string): void
}
const SocialSignOn: FunctionComponent<IProps> = ({ isSignup, handleSsoError }) => {
  const theme = useContext(ThemeContext)
  const router = useRouter()

  useEffect(() => {
    if (!router.isReady) return

    // if api server redirected with error query string set an send error message back to parent element
    // to display on screen
    if (router.query.error) {
      handleSsoError(
        (router.query.error as string) || 'There was an error signing in with your social account.',
      )
    }
  }, [router.isReady])

  return (
    <SocialSignOnContainer>
      <Spacer
        size={isSignup ? 18 : 20}
        hideTablet
        hideMobile
      />
      <RoundedButton
        href={`${process.env.API_URL}/auth/facebook`}
        text="Continue with Facebook"
        background={theme.colors.white}
        color={theme.colors.grey25}
        boxShadow={`4px 8px 24px ${theme.colors.transparentBlackAlpha10}`}
        fontSize={12}
        radius={50}
        height={60}
        renderLeftIcon={renderFacebookIcon}
        iconPadding={24}
        padding="0 25px"
        fullWidth
        borderColor={theme.colors.white}
        borderWidth={1}
        letterSpacing="1px"
        hoverBorderColor={theme.colors.blue4}
      />
      <Spacer
        size={24}
        desktopSize={32}
        desktopLargeSize={32}
      />
      <RoundedButton
        href={`${process.env.API_URL}/auth/google`}
        text="Continue with Google"
        background={theme.colors.white}
        color={theme.colors.grey25}
        boxShadow={`4px 8px 24px ${theme.colors.transparentBlackAlpha10}`}
        fontSize={12}
        radius={50}
        height={60}
        renderLeftIcon={renderGoogleIcon}
        iconPadding={24}
        padding="0 25px"
        fullWidth
        borderColor={theme.colors.white}
        borderWidth={1}
        letterSpacing="1px"
        hoverBorderColor={theme.colors.blue4}
      />
      <Spacer
        size={24}
        desktopSize={32}
        desktopLargeSize={32}
      />
      <RoundedButton
        href={`${process.env.API_URL}/auth/apple`}
        text="Continue with Apple"
        background={theme.colors.white}
        color={theme.colors.grey25}
        boxShadow={`4px 8px 24px ${theme.colors.transparentBlackAlpha10}`}
        fontSize={12}
        radius={50}
        height={60}
        renderLeftIcon={renderAppleIcon}
        iconPadding={24}
        padding="0 25px"
        fullWidth
        borderColor={theme.colors.white}
        borderWidth={1}
        letterSpacing="1px"
        hoverBorderColor={theme.colors.blue4}
      />
    </SocialSignOnContainer>
  )
}

export default SocialSignOn
