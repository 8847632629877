import SVG from '../SVG'
import * as T from '../types'

const Facebook = (props: T.ISVGProps) => (
  <SVG
    {...props}
    viewBox="0 0 12 22"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.78897 22V11.9648H11.327L11.8567 8.05387H7.78897V5.5569C7.78897 4.42458 8.11918 3.65298 9.82473 3.65298L12 3.65201V0.154089C11.6237 0.106512 10.3325 0 8.8303 0C5.69405 0 3.54703 1.82256 3.54703 5.16969V8.05387H0V11.9648H3.54703V22H7.78897Z"
  />
)

export default Facebook
