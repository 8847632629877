import { ISVGProps } from '../types'

const Apple = (props: ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 49 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.8084 31.8828C40.7328 24.2826 47.0062 20.6373 47.2867 20.4532C43.7609 15.2981 38.2681 14.5907 36.3131 14.5103C31.6422 14.0359 27.1945 17.2616 24.8249 17.2616C22.4602 17.2616 18.7991 14.5798 14.9258 14.6481C9.83048 14.7237 5.13397 17.6116 2.51193 22.1727C-2.77972 31.3523 1.15822 44.9564 6.3145 52.4067C8.83532 56.0483 11.8415 60.146 15.7856 59.996C19.5869 59.846 21.0211 57.5374 25.6164 57.5374C30.2117 57.5374 31.502 59.996 35.5228 59.9192C39.6108 59.8448 42.2023 56.2081 44.7024 52.5518C47.5964 48.3212 48.7879 44.2247 48.8587 44.0174C48.7696 43.9759 40.8901 40.9575 40.8084 31.8828Z"
      fill="black"
    />
    <path
      d="M33.2533 9.5796C35.3448 7.04049 36.7619 3.51232 36.3765 0C33.3581 0.121955 29.7043 2.00617 27.5372 4.54284C25.5957 6.79414 23.898 10.3784 24.3529 13.8273C27.7201 14.0883 31.1544 12.1138 33.2533 9.5796Z"
      fill="black"
    />
  </svg>
)

export default Apple
