import { FC } from 'react'

import * as T from '../types'

const YouTubeIcon: FC<T.ISVGProps> = ({
  width = '100%',
  height = '100%',
  viewBox = '0 0 1000 700',
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    enableBackground="new 0 0 1000 700"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill="#FF0000"
        d="M979.1040649,109.3061981c-11.5006714-43.0248489-45.385437-76.9095688-88.4103394-88.4101563 C812.7086792,0,500.0000305,0,500.0000305,0S187.2913055,0,109.3062897,20.8960381 c-43.0249405,11.5005913-76.9096603,45.3853111-88.4103012,88.4101563C0,187.2911072,0,350.0001221,0,350.0001221 s0,162.7089844,20.8959904,240.6939087c11.500639,43.0248413,45.3853607,76.9095459,88.4103012,88.4101563 c77.9850159,20.8960571,390.6937256,20.8960571,390.6937256,20.8960571s312.7086487,0,390.6936951-20.8960571 c43.0249023-11.5006104,76.909668-45.3853149,88.4103394-88.4101563C1000,512.7091064,1000,350.0001221,1000,350.0001221 S1000,187.2911072,979.1040649,109.3061981z"
      />
      <polygon
        fill="#FFFFFF"
        points="400,500 659.7963257,350.004425 400,200"
      />
    </g>
  </svg>
)

export default YouTubeIcon
