import React, { FunctionComponent, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import ExclamationPointCircle from 'components/SVG/ExclamationPointCircle'
import Spacer from 'components/Spacer'

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled(FlexRow)`
  flex-direction: column;
`

const ErrorContainer = styled(FlexRow)`
  justify-content: center;
  width: 100%;
  background: ${props => props.theme.colors.red4};
  border-bottom: 3px solid ${props => props.theme.colors.red};
  border-radius: 12px;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 56px;
`

const IconContainer = styled(FlexRow)`
  justify-content: center;
  width: 20px;
  height: 20px;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    width: 28px;
    height: 28px;
  }
`

const ErrorText = styled(FlexRow)`
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  line-height: 19px;
  font-style: normal;
  font-weight: 900;
  color: ${props => props.theme.colors.neutral900};
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    font-size: 16px;
    line-height: 22px;
  }
`

interface IProps {
  errorText: string
}
const NotificationToast: FunctionComponent<IProps> = ({ errorText }) => {
  const theme = useContext(ThemeContext)

  return (
    <Container>
      <ErrorContainer>
        <IconContainer>
          <ExclamationPointCircle
            width="100%"
            height="100%"
            fill={theme.colors.red}
          />
        </IconContainer>
        <Spacer size={24} />
        <ErrorText>{errorText}</ErrorText>
      </ErrorContainer>
      <Spacer
        size={24}
        desktopSize={36}
        desktopLargeSize={36}
      />
    </Container>
  )
}

export default NotificationToast
